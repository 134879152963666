import React from "react";
import { MdVerified } from "react-icons/md";
import { Avatar, Flex, Typography } from "antd";

import useCommonJobCard from "./useCommonJobCard";

const { Text, Title } = Typography;

const CommonJobCardHeader = () => {
  const { job, profileImagePlaceholder, jobLinkTo } = useCommonJobCard();

  return (
    <Flex justify="space-between" style={{ marginBottom: 10 }}>
      <a href={jobLinkTo} target="_blank" rel="noreferrer">
        <Avatar
          shape="square"
          size={64}
          src={job.business?.profileImageUrl ?? profileImagePlaceholder}
          style={{
            marginRight: 8,
            border: "1px solid #f0f0f0",
            borderColor: "#e8e8e8",
          }}
        />
      </a>
      <div style={{ flex: 1, minWidth: 0 }}>
        <a href={jobLinkTo} target="_blank" rel="noreferrer">
          <Title level={5} style={{ marginBottom: 0 }}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "100%",
              }}
            >
              {job.title}
            </div>
          </Title>
        </a>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              fontSize: 13,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
          >
            {job.business?.name ?? ""}
          </div>
          {job.business?.isVerified && (
            <div style={{ minWidth: 20 }}>
              <MdVerified
                style={{
                  fontSize: 13,
                  color: "#1677FF",
                  marginTop: 5,
                  marginRight: 4,
                  float: "right",
                }}
              />
            </div>
          )}
        </div>
        <Text style={{ fontSize: 13 }}>
          {job.salaryRange?.spanishName}{" "}
          {job.salaryRange?.spanishName === "Negociable"
            ? ""
            : job.paymentFrequency?.spanishName.toLowerCase()}
        </Text>
      </div>
    </Flex>
  );
};

export default CommonJobCardHeader;

import React from "react";
import { Avatar, Badge, Flex, Tooltip, Typography } from "antd";

import useCommonUserStudentCard from "./useCommonUserStudentCard";

const { Text, Title } = Typography;

const CommonUserStudentCardHeader = () => {
  const { userStudent, profileImagePlaceholder, userStudentLinkTo } =
    useCommonUserStudentCard();

  return (
    <Flex justify="space-between" style={{ marginBottom: 10 }}>
      <a href={userStudentLinkTo} target="_blank" rel="noreferrer">
        <Avatar
          shape="square"
          size={64}
          src={userStudent.profileImageUrl ?? profileImagePlaceholder}
          style={{ marginRight: 8 }}
        />
      </a>
      <div style={{ flex: 1, minWidth: 0 }}>
        <a href={userStudentLinkTo} target="_blank" rel="noreferrer">
          <Title level={5} style={{ marginBottom: 0 }}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "100%",
              }}
            >
              {`${userStudent.firstName} ${userStudent.lastName}`}
            </div>
          </Title>
        </a>

        {userStudent?.career && (
          <Text style={{ fontSize: 13 }}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "100%",
              }}
            >
              {userStudent.career.spanishName}
            </div>
          </Text>
        )}

        {userStudent?.newCareerRequest && (
          <a
            href={userStudentLinkTo}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Tooltip
                title={
                  <>
                    Estamos revisando la validez de la carrera
                    <b style={{ display: "block" }}>
                      {userStudent.newCareerRequest.name}
                    </b>
                  </>
                }
              >
                <div
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                  }}
                >
                  <Text style={{ fontSize: 13 }}>
                    {userStudent.newCareerRequest.name}
                  </Text>
                </div>
              </Tooltip>
              <Badge
                color="#1677ff"
                style={{ marginInlineStart: 10 }}
                status="processing"
              />
            </div>
          </a>
        )}

        {userStudent.university && (
          <div
            style={{
              fontSize: 13,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
          >
            {userStudent.university.name}
          </div>
        )}

        {userStudent.newUniversityRequest && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Tooltip
              title={
                <>
                  Estamos revisando la validez de la universidad
                  <b style={{ display: "block" }}>
                    {userStudent.newUniversityRequest.name}
                  </b>
                </>
              }
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "90%",
                }}
              >
                <Text style={{ fontSize: 13 }}>
                  {userStudent.newUniversityRequest.name}
                </Text>
              </div>
            </Tooltip>
            <Badge
              color="#1677ff"
              style={{ marginInlineStart: 10 }}
              status="processing"
            />
          </div>
        )}
      </div>
    </Flex>
  );
};

export default CommonUserStudentCardHeader;
